import React from "react";
import { StyleSheet, ScrollView } from "react-native";
import { Text, View, Card, Wrapper } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Spacer from "@components/atoms/Spacer";
import Fonts from "@constants/Fonts";

export default function PrivacyPolicy() {
  return (
    <ScrollView>
      <Wrapper>
        <Card style={styles.container}>
          <View style={styles.content}>
            <Text style={styles.section}>1. 個人情報の取扱い</Text>
            <Spacer height={16} />

            <View style={styles.indent}>
              <Text style={styles.text}>〔法令遵守〕</Text>
              <Text style={styles.text}>
                株式会社ベクトルおよびそのグループ会社（以下「ベクトルグループ」といいます）は個人情報（個人情報の保護に関する法律
                （平成15年法律第57号、以下、「個人情報保護法」という。）所定の個人情報をいう。）を、
                個人情報保護法等関係法令及び本プライバシーポリシーに従って取り扱います。
              </Text>

              <Spacer height={16} />

              <Text style={styles.text}>〔利用目的〕</Text>
              <Text style={styles.text}>
                ベクトルグループは、個人情報を公正・適切な手段によって取得し、[表]の「利用目的」に示す範囲内で利用します。下記の［表］の
                「個人情報の種類」に示す以外の種類の個人情報を取得する場合は、個別に利用目的を通知または公表したうえで利用します。
              </Text>

              <Spacer height={16} />
              <Text style={styles.text}>〔委託先管理〕</Text>
              <Text style={styles.text}>
                ベクトルグループは、個人情報に関連する業務の一部を委託することがあります。当該委託にあたっては、業務委託契約を締結する等、
                委託先を十分に監督するようにしております。
              </Text>

              <Spacer height={16} />
              <Text style={styles.text}>〔第三者提供〕</Text>
              <Text style={styles.text}>
                ベクトルグループは、本人の同意がない限り個人情報を第三者に開示・提供しません。ただし、法令に定める除外事由が存在する場合を除きます。
              </Text>

              <Spacer height={16} />
              <Text style={styles.text}>〔共同利用〕</Text>
              <Text style={styles.text}>
                ベクトルグループは、[表]の「共同利用」を行うほか、他の企業等と共同開催するイベントの実施等において、個人情報を共同利用する場合があります。
                その場合、あらかじめ利用目的、個人データの項目、利用者の範囲および管理責任者を明確にし、通知または公表します。
              </Text>
            </View>

            <Spacer height={16} />

            <Text style={styles.section}>2. 収集する個人情報及び利用目的</Text>
            <Spacer height={16} />

            <View style={styles.indent}>
              <Text style={styles.text}>
                ベクトルグループは，以下の種類の個人情報について、下記の[表]の「利用目的」の範囲で利用します。
              </Text>
              <Spacer height={16} />
              <Text style={styles.text}>〔表〕</Text>
            </View>

            <ScrollView horizontal>
              <View style={styles.table}>
                <View style={[styles.row, styles.head]}>
                  <Card style={styles.cell}>
                    <Text style={styles.text}>個人情報の種類</Text>
                  </Card>

                  <Card style={styles.cell}>
                    <Text style={styles.text}>利用目的</Text>
                  </Card>
                  <Card style={styles.cell}>
                    <Text style={styles.text}>取得方法</Text>
                  </Card>
                  <Card style={styles.cell}>
                    <Text style={styles.text}>共同利用</Text>
                  </Card>
                </View>

                <View style={styles.row}>
                  <View style={styles.cell}>
                    <Text style={styles.text}>
                      クライアント・マスメディア・関係会社のご担当者の個人情報
                    </Text>
                  </View>

                  <View style={styles.cell}>
                    <Text style={styles.text}>事業に関するご案内・ご連絡</Text>
                    <Text style={styles.text}>出版物，定期刊行物等の送付</Text>
                  </View>

                  <View style={styles.cell}>
                    <Text style={styles.text}>本人より直接取得 </Text>
                  </View>
                  <View style={styles.cell}>
                    <Text style={styles.text}>共同利用の項目</Text>
                    <Text style={styles.text}>
                      ・マスメディアのご担当者の氏名、勤務先住所・電話番号、メールアドレス等の連絡先情報
                    </Text>

                    <Spacer height={8} />
                    <Text style={styles.text}>共同利用の範囲</Text>
                    <Text style={styles.text}>
                      ・株式会社ベクトル、同社の連結子会社、および同社の持分法適用関連会社
                    </Text>

                    <Spacer height={8} />
                    <Text style={styles.text}>共同利用者の利用目的</Text>
                    <Text style={styles.text}>
                      ・事業に関するご案内・ご連絡
                    </Text>

                    <Spacer height={8} />
                    <Text style={styles.text}>
                      個人データの管理について責任を有する者
                    </Text>
                    <Text style={styles.text}>・株式会社ベクトル</Text>
                  </View>
                </View>

                <View style={styles.row}>
                  <View style={styles.cell}>
                    <Text style={styles.text}>
                      ベクトルグループのサービスをご利用し、又は物品をご購入いただいた方の個人情報
                    </Text>
                  </View>

                  <View style={styles.cell}>
                    <Text style={styles.text}>
                      サービスまたは物品の提供、それに伴うご案内・ご連絡
                    </Text>
                  </View>

                  <View style={styles.cell}>
                    <Text style={styles.text}>本人より直接取得</Text>
                  </View>

                  <View style={styles.cell}>
                    <Text style={styles.text}>なし</Text>
                  </View>
                </View>

                <View style={styles.row}>
                  <View style={styles.cell}>
                    <Text style={styles.text}>
                      ベクトルグループに入社を希望される方に関する個人情報
                    </Text>
                  </View>

                  <View style={styles.cell}>
                    <Text style={styles.text}>
                      選考活動の実施に伴う業務・連絡
                    </Text>
                  </View>

                  <View style={styles.cell}>
                    <Text style={styles.text}>
                      本人より直接取得、第三者提供により取得（人材紹介会社、社員紹介等）
                    </Text>
                  </View>

                  <View style={styles.cell}>
                    <Text style={styles.text}>共同利用の項目</Text>
                    <Text style={styles.text}>
                      ・応募者の氏名・連絡先・経歴・履歴書等のご提出いただいた情報
                    </Text>

                    <Spacer height={8} />
                    <Text style={styles.text}>共同利用の範囲</Text>
                    <Text style={styles.text}>
                      ・株式会社ベクトル、同社の連結子会社、および同社の持分法適用関連会社
                    </Text>

                    <Spacer height={8} />
                    <Text style={styles.text}>共同利用者の利用目的</Text>
                    <Text style={styles.text}>
                      ・選考活動の実施に伴う業務・連絡
                    </Text>

                    <Spacer height={8} />
                    <Text style={styles.text}>
                      個人データの管理について責任を有する者
                    </Text>
                    <Text style={styles.text}>・株式会社ベクトル</Text>
                  </View>
                </View>

                <View style={styles.row}>
                  <View style={styles.cell}>
                    <Text style={styles.text}>
                      ベクトルグループにお問合わせ等をいただいた方の個人情報
                    </Text>
                  </View>

                  <View style={styles.cell}>
                    <Text style={styles.text}>
                      お問合わせ・ご要望・苦情等への対応
                    </Text>
                  </View>

                  <View style={styles.cell}>
                    <Text style={styles.text}>
                      本人より直接取得（電話、電子メール、ファクシミリ・手紙等
                    </Text>
                  </View>

                  <View style={styles.cell}>
                    <Text style={styles.text}>共同利用の項目</Text>
                    <Text style={styles.text}>
                      ・氏名・連絡先等のお寄せいただいた情報
                    </Text>

                    <Spacer height={8} />
                    <Text style={styles.text}>共同利用の範囲</Text>
                    <Text style={styles.text}>
                      ・株式会社ベクトル、同社の連結子会社、および同社の持分法適用関連会社
                    </Text>

                    <Spacer height={8} />
                    <Text style={styles.text}>共同利用者の利用目的</Text>
                    <Text style={styles.text}>
                      ・お問合わせ・ご要望・苦情等への対応
                    </Text>
                  </View>
                </View>
              </View>
            </ScrollView>

            <Spacer height={16} />

            <Text style={styles.section}>3. 安全管理措置</Text>
            <Spacer height={16} />

            <View style={styles.indent}>
              <Text style={styles.text}>
                ベクトルグループは、収集した情報の漏えい、滅失または毀損の防止その他収集した情報の適切な管理のために必要な措置を講じます。
                ベクトルグループ各社では、社内体制の整備・個⼈情報保護マネジメントシステムの継続的改善、全体的な教育活動を実施致します。
              </Text>
            </View>

            <Spacer height={16} />

            <Text style={styles.section}>
              4. 個人情報の開示、訂正及び利用停止その他の窓口
            </Text>
            <Spacer height={16} />
            <View style={styles.indent}>
              <Text style={styles.text}>
                ベクトルグループが収集した個人情報については、個人情報保護法に従い、開示、訂正又は利用停止等の請求等をすることができます。
                この場合は、本人確認のための資料の提出が必要になる場合がある他、開示については手数料をお支払いいただきます。
                その他、個人情報の取扱いに関するお問い合わせ先は以下になります。
              </Text>

              <Spacer height={16} />
              <Text style={styles.text}>【お問合せ先】</Text>
              <Text style={styles.text}>
                開⽰等の請求はベクトルグループ所定の様式によりお願いしております。開⽰請求、その他個⼈情報に関するお問い合わせは、下記までご連絡ください。
              </Text>

              <Spacer height={16} />

              <Card style={styles.contact}>
                <Text style={styles.text}>
                  受付時間:月曜日～金曜日（祝祭日、年末年始を除く）9:00〜18:00
                </Text>
                <Text style={styles.text}>
                  連絡先:株式会社ベクトル（個人情報問合せ窓口)
                  privacy@vectorinc.co.jp
                </Text>
              </Card>
            </View>

            <Spacer height={16} />

            <Text style={styles.section}>
              5. 本プライバシーポリシーの見直し及び改訂
            </Text>
            <Spacer height={16} />
            <View style={styles.indent}>
              <Text style={styles.text}>
                本プライバシーポリシーは必要に応じ，見直し及び改訂を行います。本プライバシーポリシーの改訂に伴い，既に取得した個人情報の取扱いに変更がある場合には，個人情報保護法所定の手続に従います。
              </Text>

              <Spacer height={16} />
              <Text style={styles.text}>制定日:2005年1月31日</Text>
              <Text style={styles.text}>最終改定日:2020年6月22日</Text>
              <Text style={styles.text}>
                株式会社ベクトル 代表取締役 長谷川 創
              </Text>
            </View>
          </View>
        </Card>
      </Wrapper>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingBottom: 32,
  },
  content: {
    padding: 24,
  },
  section: {
    ...Fonts.xlr100,
  },
  text: {
    ...Fonts.lr130,
  },
  indent: {
    paddingLeft: 8,
  },
  contact: {
    padding: 8,
    backgroundColor: Colors.gray10,
  },
  table: {
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderColor: Colors.gray,
    borderStyle: "solid",
  },
  head: {
    backgroundColor: Colors.gray10,
  },
  row: {
    flexDirection: "row",
  },
  cell: {
    borderRightWidth: 1,
    borderTopWidth: 1,
    borderColor: Colors.gray,
    borderStyle: "solid",
    padding: 8,
    width: 300,
  },
});

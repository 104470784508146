import React, { useMemo } from "react";
import Spacer from "@components/atoms/Spacer";
import Divider from "@components/atoms/Divider";
import HashTags from "@components/molecules/HashTags";
import CampaignSection from "@components/organisms/CampaignDetail/CampaignSection";
import {
  CampaignContents$key,
  CampaignContents$data,
} from "@generated/CampaignContents.graphql";
import { graphql, useFragment } from "react-relay/hooks";
import TitleContent from "./TitleContent";

const campaignContents = graphql`
  fragment CampaignContents on Campaign {
    mediaType
    purpose
    avoidedSentence
    mCategory {
      name
    }
    socialNetwork {
      ytHashTag
      hashTag
      mainContent
      productIntro
      mentionTag
      mentionTagContent
      productUrl
      secondhand
      secondhandContent
      productSupply
    }
  }
`;

function getHashTags(campaign: CampaignContents$data): string[] {
  if (campaign.mediaType === "youtube") {
    return campaign.socialNetwork.ytHashTag !== null
      ? campaign.socialNetwork.ytHashTag.split(",").map((value) => `#${value}`)
      : [];
  }
  return campaign.socialNetwork.hashTag !== null
    ? campaign.socialNetwork.hashTag.split(",").map((value) => `#${value}`)
    : [];
}

export default function CampaignContents({
  campaignFragment,
}: {
  campaignFragment: CampaignContents$key;
}) {
  const campaign = useFragment(campaignContents, campaignFragment);
  const tags = useMemo(() => getHashTags(campaign), [campaign]);
  return (
    <CampaignSection title="お仕事の内容">
      <TitleContent
        content={
          campaign.mediaType === "other"
            ? (campaign.socialNetwork.mainContent as string)
            : (campaign.socialNetwork.productIntro as string)
        }
        title="依頼内容"
      />
      <Spacer height={16} />
      <Divider />
      <Spacer height={16} />
      <TitleContent content={campaign.purpose as string} title="撮影の仕方" />
      <Spacer height={16} />
      <Divider />
      <Spacer height={16} />
      <TitleContent
        content={campaign.avoidedSentence as string}
        title="NG表現"
      />

      {campaign.mediaType === "other" ? (
        <>
          <Spacer height={16} />
          <Divider />
          <Spacer height={16} />
          <TitleContent
            content={
              campaign.socialNetwork.hashTag !== null
                ? campaign.socialNetwork.hashTag
                : "-"
            }
            title="ハッシュタグ・メンションタグ・記載URL等"
          />
        </>
      ) : (
        tags.length > 0 && (
          <>
            <Spacer height={16} />
            <Divider />
            <Spacer height={16} />
            <TitleContent
              content="下記のハッシュタグを付けて、投稿お願いします。"
              title="指定ハッシュタグ"
            >
              <Spacer height={4} />
              {tags.length > 0 && <HashTags tags={tags} />}
            </TitleContent>
          </>
        )
      )}

      {campaign.socialNetwork.mentionTag === true &&
        campaign.socialNetwork.mentionTagContent !== null && (
          <>
            <Spacer height={16} />
            <Divider />
            <Spacer height={16} />
            <TitleContent
              content={campaign.socialNetwork.mentionTagContent}
              title="指定メンションタグ"
            />
          </>
        )}

      {campaign.mediaType !== "other" && (
        <>
          <Spacer height={16} />
          <Divider />
          <Spacer height={16} />
          <TitleContent
            content={
              campaign.socialNetwork.productUrl !== null
                ? campaign.socialNetwork.productUrl
                : "-"
            }
            title="掲載して欲しいURL"
          />
        </>
      )}

      <Spacer height={16} />
      <Divider />
      <Spacer height={16} />

      <TitleContent
        content={
          campaign.socialNetwork.secondhand === true
            ? (campaign.socialNetwork.secondhandContent as string)
            : "-"
        }
        title="二次利用"
      />

      {campaign.socialNetwork.productSupply !== null ? (
        <>
          <Spacer height={16} />
          <Divider />
          <Spacer height={16} />
          <TitleContent
            content={campaign.socialNetwork.productSupply}
            title="提供表記"
          />
        </>
      ) : null}
    </CampaignSection>
  );
}

/**
 * @generated SignedSource<<bc78d8ed66559f939f5bc216074f7868>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type MediaType = "cast" | "ins_highlight" | "ins_image" | "ins_live" | "ins_story" | "ins_video" | "other" | "tiktok" | "twitter_image" | "twitter_video" | "youtube";
import { FragmentRefs } from "relay-runtime";
export type CampaignContents$data = {
  readonly avoidedSentence: string | null;
  readonly mCategory: {
    readonly name: string;
  };
  readonly mediaType: MediaType;
  readonly purpose: string | null;
  readonly socialNetwork: {
    readonly hashTag: string | null;
    readonly mainContent: string | null;
    readonly mentionTag: boolean | null;
    readonly mentionTagContent: string | null;
    readonly productIntro: string | null;
    readonly productSupply: string | null;
    readonly productUrl: string | null;
    readonly secondhand: boolean | null;
    readonly secondhandContent: string | null;
    readonly ytHashTag: string | null;
  };
  readonly " $fragmentType": "CampaignContents";
};
export type CampaignContents$key = {
  readonly " $data"?: CampaignContents$data;
  readonly " $fragmentSpreads": FragmentRefs<"CampaignContents">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CampaignContents",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mediaType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "purpose",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avoidedSentence",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MCategory",
      "kind": "LinkedField",
      "name": "mCategory",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SocialNetwork",
      "kind": "LinkedField",
      "name": "socialNetwork",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ytHashTag",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hashTag",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mainContent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "productIntro",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mentionTag",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "mentionTagContent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "productUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secondhand",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secondhandContent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "productSupply",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Campaign",
  "abstractKey": null
};

(node as any).hash = "d1a0fd86b889fb722fb4f0c34e258307";

export default node;

import React from "react";
import { StyleSheet } from "react-native";
import Spacer from "@components/atoms/Spacer";
import { NumberField, ErrorText } from "@components/molecules/TextInput";
import { View, Text, Card } from "@components/atoms/Themed";
import Colors from "@constants/Colors";
import Fonts from "@constants/Fonts";
import CustomIcon from "@components/atoms/Icon";
import { graphql, useFragment } from "react-relay/hooks";
import { ApplyReward$key } from "@generated/ApplyReward.graphql";
import numberFormat from "@lib/util/numberFormat";
import Avatar from "@components/atoms/Avatar";
import { ApplyPrice, ProposalPrice, KeyboardId } from "@constants/App";
import ReactForm from "react-hook-form";
import { InputData } from "@components/organisms/Apply/type";

const applyReward = graphql`
  fragment ApplyReward on Campaign {
    mediaType
    advertiserName
    askingPrice
    prType
  }
`;

export default function ApplyReward({
  campaignFragment,
  control,
  error,
}: {
  campaignFragment: ApplyReward$key;
  control: ReactForm.Control<InputData>;
  error: string | undefined;
}) {
  const campaign = useFragment(applyReward, campaignFragment);
  return (
    <Card style={styles.container}>
      <View style={styles.head}>
        <CustomIcon color={Colors.black} name="money" size={18} />
        <Spacer width={7} />
        <Text style={styles.title}>{ApplyPrice}</Text>
      </View>
      {campaign.askingPrice != null && campaign.prType === "rewarded" && (
        <>
          <Spacer height={24} />
          <View style={styles.info}>
            <View style={styles.advertiserWrap}>
              <Avatar uri={null} />
              <Spacer width={8} />
              <View style={styles.advertiserNameTail}>
                <Text
                  ellipsizeMode="tail"
                  numberOfLines={1}
                  style={styles.advertiser}
                >
                  {campaign.advertiserName}
                </Text>
              </View>
            </View>
            <View style={styles.askingPriceWrap}>
              <Text style={styles.askingPriceLabel}>{ProposalPrice}</Text>
              <Spacer width={12} />
              <Text style={styles.askingPrice}>
                ¥{numberFormat(campaign.askingPrice)}
              </Text>
            </View>
          </View>
          <Spacer height={8} />
          <Text style={[Fonts.sr100]}>
            {ProposalPrice}を参考に{ApplyPrice}を決めてください
          </Text>
        </>
      )}

      <Spacer height={24} />

      <View style={styles.form}>
        <View>
          <Text style={styles.yen}>¥</Text>
        </View>
        <Spacer width={8} />
        <View style={styles.unitPriceInputWrap}>
          <NumberField
            control={control}
            inputAccessoryViewID={KeyboardId}
            name="customPrice"
            style={styles.unitPriceInput}
          />
          {error !== undefined && <ErrorText error={error} />}
        </View>
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  head: {
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    ...Fonts.xlb160,
    color: Colors.black,
  },
  info: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  advertiserWrap: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  advertiserNameTail: {
    flex: 1,
  },
  advertiser: {
    ...Fonts.mr100,
  },
  askingPriceWrap: {
    flexDirection: "row",
    alignItems: "center",
  },
  askingPriceLabel: {
    ...Fonts.lb100,
  },
  askingPrice: {
    ...Fonts.ensb100,
    color: Colors.orange,
  },
  form: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  yen: {
    color: Colors.black,
    ...Fonts.enmb100,
  },
  unitPriceInputWrap: {
    flex: 1,
  },
  unitPriceInput: {
    height: 56,
    ...Fonts.enlb100,
    lineHeight: 46,
    paddingRight: 12,
    textAlign: "right",
    alignItems: "center",
    justifyContent: "center",
  },
});

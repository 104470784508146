import Constants from "expo-constants";

const eas = Constants.expoConfig?.extra?.eas;
export const { projectId } = eas;
export const env = Constants.expoConfig?.extra?.env;
export const webEndpoint = Constants.expoConfig?.extra?.webEndpoint;
export const apiEndpoint = Constants.expoConfig?.extra?.apiEndpoint;
export const oauthUniversalLinkRoot =
  Constants.expoConfig?.extra?.oauthUniversalLinkRoot;
export const oauthUniversalLinkPath =
  Constants.expoConfig?.extra?.oauthUniversalLinkPath;
export const tokenKey = Constants.expoConfig?.extra?.tokenKey;
export const refreshTokenKey = Constants.expoConfig?.extra?.refreshTokenKey;
export const conversionType = Constants.expoConfig?.extra?.conversionType;
export const lastRetension = Constants.expoConfig?.extra?.lastRetension;
export const appVersion = Constants.expoConfig?.version;
export const runtimeVersion = Constants.expoConfig?.runtimeVersion;
export const contactUrl = Constants.expoConfig?.extra?.contactUrl;
export const companyUrl = Constants.expoConfig?.extra?.companyUrl;
export const lpUrl = Constants.expoConfig?.extra?.lpUrl;
export const instagramGuideUrl = Constants.expoConfig?.extra?.instagramGuideUrl;
export const appStoreUrl = Constants.expoConfig?.extra?.appStoreUrl;
export const facebookAppId = Constants.expoConfig?.extra?.facebookAppId;
export const twitterClientId = Constants.expoConfig?.extra?.twitterClientId;
export const lineClientId = Constants.expoConfig?.extra?.lineClientId;
export const appleClientId = Constants.expoConfig?.extra?.appleClientId;
export const agencyWebsiteUrl = Constants.expoConfig?.extra?.agencyWebsiteUrl;
export const slackWebhookUrl = Constants.expoConfig?.extra?.slackWebhookUrl;
export const questionUrl = Constants.expoConfig?.extra?.questionUrl;
export const firebaseConfig = Constants.expoConfig?.extra?.firebase;
export const clarityProjectId = Constants.expoConfig?.extra?.clarityProjectId;
